import { createContext, useContext, ComponentType, PropsWithChildren } from 'react'

import { WebSocketContextType } from './types'
import { useWebSocketService } from './service'

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined)

export const WebSocketProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useWebSocketService()

  return <WebSocketContext.Provider value={service}>{children}</WebSocketContext.Provider>
}

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext)
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider')
  }
  return context
}
