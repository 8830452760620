import { useState } from 'react'
import { SocketType } from './types'
import { useStorageContext } from '../storage/hooks/use-storage-context'

import { io } from 'socket.io-client'
import { STORAGE_AUTH_KEY } from '@/constants/auth/storage-auth-key'
import { SOCKET_EVENTS } from './events'
import { useBalanceContext } from '../balance/hooks/use-balance-context'

const SOCKET_URL = process.env.REACT_APP_API_URL

export const useWebSocketService = () => {
  const [socket, setSocket] = useState<SocketType>(null)
  const { refreshBalances } = useBalanceContext()
  const storage = useStorageContext()

  const updateBalances = () => {
    refreshBalances()
  }

  const initSocket = () => {
    const socket = io(SOCKET_URL as string)
    socket.connect()
    setSocket(socket)
  }

  const initGlobalListeners = (merchantId: string) => {
    if (socket) {
      connectAndSignIn(merchantId)

      socket.on(SOCKET_EVENTS.SWAP_STATUS_UPDATE, () => {
        updateBalances()
      })
      socket.on(SOCKET_EVENTS.CONFIRMED_TRANSACTIONS, () => {
        updateBalances()
      })
    }
  }

  const connectAndSignIn = (merchantId: string) => {
    if (socket && merchantId) {
      socket.on(SOCKET_EVENTS.CONNECT, () => {
        const token: string = storage.getItem(STORAGE_AUTH_KEY)?.trim() as string
        socket.emit(SOCKET_EVENTS.SIGN_IN_MERCHANT, {
          token,
          merchantId
        })
      })
    }
  }

  const unSubscribeListeners = () => {
    if (socket) {
      socket.off(SOCKET_EVENTS.SWAP_STATUS_UPDATE)
      socket.off(SOCKET_EVENTS.CONFIRMED_TRANSACTIONS)
    }
  }

  return { socket, initSocket, initGlobalListeners, unSubscribeListeners }
}
