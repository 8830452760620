import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ELanguagesCode, SUPPORTED_LANGUAGES } from '@/constants/languages'

const telegramUserInfo = window.Telegram.WebApp.initDataUnsafe.user
const systemLang = telegramUserInfo?.language_code

let defaultLng: string = ELanguagesCode.EN
const storedLng = localStorage.getItem('i18nextLng')

if (!storedLng && systemLang && SUPPORTED_LANGUAGES.includes(systemLang)) {
  localStorage.setItem('i18nextLng', systemLang)
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: defaultLng,
    supportedLngs: SUPPORTED_LANGUAGES,
    debug: true,
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng'
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
